<template>
  <section class="edit-list-view">
    <div class="edit-list-view__header px-4 pt-4 pb-3">
      <CiBreadcrumbs :breadcrumbs="breadcrumbs" class="mb-2" />
      <div style="display: flex; align-items: center;">
        <RdxButton
          rect
          :to="{ name: 'CarsInventoryPublications' }"
          class="ci-action--back"
        >
          <RdxIcon class="ri-arrow-left-line rdx-icon--24" />
        </RdxButton>
        <CiHeading level="1" class="ml-2">
          {{ listingTitle || "..." }}
        </CiHeading>
        <div v-if="userCanEditListing" class="edit-list-view__actions">
          <RdxButton
            :disabled="!isEdited"
            outline
            class="mr-2"
            @click="openWarningModal"
          >
            {{ $t("global.cancel") }}
          </RdxButton>
          <RdxButton
            :disabled="!isEdited || pending.load"
            :loading="pending.update"
            filled
            @click="updateListing"
          >
            {{ $t("global.save") }}
          </RdxButton>
        </div>
      </div>
    </div>

    <div class="edit-list-view__inner">
      <div class="p-5">
        <div class="row edit-list-view__grid">
          <div>
            <CiHeading level="2" class="mb-2">
              {{ $t("publications.shared-form.header") }}
            </CiHeading>
            <RdxInfo>
              {{ $t("publications.shared-form.description") }}
            </RdxInfo>
            <RdxInfo class="mb-0">
              {{ $t("publications.shared-form.owned-by") }}:
            </RdxInfo>
            <div data-cy="edit-list-view__owner-name">
              {{ ownerName }}
            </div>
          </div>

          <div class="col-xl-9">
            <div
              v-if="!userCanEditListing && !pending.load"
              class="ci-publications__info-box ci-publications__info-box--error
              mb-3"
            >
              <RdxIcon icon="ri-information-line" class="mr-1" />
              {{ $t("publications.block-editing-message") }}
            </div>
            <RdxInput
              v-model="listing.title"
              :disabled="pending.load || pending.update || !userCanEditListing"
              :label="$t('publications.shared-form.title-label')"
              :placeholder="$t('publications.shared-form.title-placeholder')"
            />

            <div class="row">
              <div class="col-6">
                <RdxSelect
                  v-model="selectedButtonSet"
                  :disabled="
                    pending.load || pending.update || !userCanEditListing
                  "
                  :options="sets"
                  vm-track-by="id"
                  :label="$t('publications.shared-form.button_set-input-label')"
                  :placeholder="
                    $t('publications.shared-form.button_set-input-placeholder')
                  "
                  class="mb-2"
                >
                  <template #singleLabel="{ option: {attributes} }">
                    {{ attributes ? attributes.name : "" }}
                  </template>
                  <template #option="{ option: {attributes} }">
                    {{ attributes ? attributes.name : "" }}
                  </template>
                </RdxSelect>
                <div class="mb-3">
                  <router-link
                    :to="{
                      name: 'CarsInventoryButtonSets'
                    }"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ $t("publications.shared-form.show-all-sets") }}
                  </router-link>
                  <a
                    class="edit-list-view__link"
                    data-cy="edit-list-view__link"
                    @click="openButtonSetModal"
                    >{{ $t("publications.shared-form.create-new-set") }}</a
                  >
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <RdxSelect
                  v-model="selectedSortOption"
                  :disabled="
                    pending.load || pending.update || !userCanEditListing
                  "
                  :options="sortOptions"
                  :placeholder="
                    $t('publications.shared-form.sorting-option-placeholder')
                  "
                  :label="$t('publications.shared-form.sorting-option-label')"
                >
                  <template #singleLabel="{ option: { attr, order } }">
                    {{
                      $t(
                        `publications.shared-form.sorting-opts.${attr}.${order}`
                      )
                    }}
                  </template>
                  <template #option="{ option: { attr, order } }">
                    {{
                      $t(
                        `publications.shared-form.sorting-opts.${attr}.${order}`
                      )
                    }}
                  </template>
                </RdxSelect>
              </div>
            </div>

            <RdxInput
              v-model="listing.uuid"
              :label="$t('publications.shared-form.list-id-input-label')"
              class="mb-0"
              disabled
            >
              <template #suffix>
                <RdxButton
                  v-tooltip="
                    $t('publications.shared-form.copy-to-clipboard-tooltip')
                  "
                  rect
                  @click="copyToClipboard"
                >
                  <RdxIcon class="ri-file-copy-line rdx-icon--18" />
                </RdxButton>
              </template>
            </RdxInput>
            <RdxInfo class="mb-3">
              {{ $t("publications.shared-form.list-id-note") }}
            </RdxInfo>

            <div class="mt-4">
              <RdxButton
                variant="red"
                :disabled="
                  pending.load || pending.update || !userCanEditListing
                "
                :loading="pending.delete"
                filled
                @click="openDeleteModal"
              >
                <span>{{
                  $t("publications.shared-form.delete-list-button")
                }}</span>
              </RdxButton>
              <RdxInfo icon="ri-information-line">
                {{ $t("publications.shared-form.delete-warrning") }}
              </RdxInfo>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CiDeleteModal
      v-if="deleteModalOpened"
      :heading="$t('publications.shared-form.delete-modal.header')"
      :paragraph="$t('publications.shared-form.delete-modal.description')"
      :delete-text="$t('global.delete')"
      :cancel-text="$t('global.cancel')"
      @cancel="closeDeleteModal"
      @delete="deleteList"
    />
    <CiWarningModal
      v-if="warningModalOpened"
      :heading="$t('global.skip-editing-modal.header')"
      :paragraph="$t('global.skip-editing-modal.description')"
      @confirm="leavePage"
      @cancel="warningModalOpened = $event"
    >
      <template #confirm>
        <RdxButton filled class="ci-warning-modal__button" @click="leavePage">
          {{ $t("global.skip-editing-modal.confirm-button") }}
        </RdxButton>
      </template>
    </CiWarningModal>
    <RdxModal
      v-if="buttonSetModalOpened"
      class="edit-list-view__modal--fullscreen"
      @close-modal="closeButtonSetModal"
    >
      <template #header>
        <span>
          {{ $t("publications.shared-form.new-button-set") }}
        </span>
      </template>
      <NewButtonSet
        in-modal
        @close-modal="closeButtonSetModal"
        @save="onButtonSetSave($event)"
      />
    </RdxModal>
  </section>
</template>

<script>
import {
  RdxIcon,
  RdxInfo,
  RdxButton,
  RdxInput,
  RdxSelect,
  RdxModal
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiBreadcrumbs from "@/components/atoms/CiBreadcrumbs.vue";
import CiHeading from "@/components/atoms/CiHeading";
import payloadRewriter from "@/utils/payload-rewriter";
import "@/utils/object-compare";
import CiDeleteModal from "@/components/CiDeleteModal/CiDeleteModal";
import CiWarningModal from "@/components/CiWarningModal/CiWarningModal";
import NewButtonSet from "@/views/cars-inventory/buttons/NewButtonSet";

export default {
  name: "EditListView",

  components: {
    RdxIcon,
    RdxInfo,
    RdxButton,
    RdxInput,
    RdxSelect,
    CiBreadcrumbs,
    CiHeading,
    CiDeleteModal,
    CiWarningModal,
    RdxModal,
    NewButtonSet
  },

  data() {
    return {
      pending: {
        load: false,
        update: false,
        delete: false
      },

      initialListing: {
        uuid: null,
        title: null,
        sort_attribute: [],
        sort_order: [],
        button_set: null
      },

      listing: {
        uuid: null,
        title: null,
        sort_attribute: [],
        sort_order: [],
        button_set: null
      },

      relationships: {
        button_set: {
          data: {
            id: null
          }
        },

        owner: {
          data: {
            id: null
          }
        }
      },

      initialButtonSet: {},
      options: {
        sort_order: [],
        sort_attribute: []
      },
      listingTitle: null,
      deleteModalOpened: false,
      sets: [],
      warningModalOpened: false,
      currentCompanyId: null,
      buttonSetModalOpened: false,
      ownerName: ""
    };
  },

  computed: {
    sortOptions() {
      const options = [];
      const { sort_order, sort_attribute } = this.options;
      for (let attr of sort_attribute) {
        for (let order of sort_order) {
          options.push({ order, attr });
        }
      }
      return options;
    },

    selectedSortOption: {
      get() {
        return this.sortOptions.find(
          o =>
            o.attr === this.listing.sort_attribute &&
            o.order === this.listing.sort_order
        );
      },

      set({ attr, order }) {
        this.listing.sort_attribute = attr;
        this.listing.sort_order = order;
      }
    },

    ID() {
      return this.$route.params?.id;
    },
    isEdited() {
      return !Object.compare(this.initialListing, this.listing);
    },
    breadcrumbs() {
      return [
        { text: "CarsInventory", link: { name: "CarsInventoryStock" } },
        {
          text: this.$t("navbar.publications"),
          link: { name: "CarsInventoryPublications" }
        },
        { text: this.listingTitle || "..." }
      ];
    },

    listingCompanyId() {
      return this.relationships.owner.data.id;
    },
    userCanEditListing() {
      return this.listingCompanyId === this.currentCompanyId;
    },
    selectedButtonSet: {
      get() {
        if (this.userCanEditListing) {
          return this.sets.find(el => el.id === this.listing.button_set);
        }
        return this.initialButtonSet;
      },

      set(buttonSet) {
        this.$set(this.listing, "button_set", buttonSet.id);
      }
    }
  },

  watch: {
    "listing.button_set"(button_set) {
      if (button_set) {
        if (!this.relationships.button_set.data) {
          this.relationships.button_set.data = {};
        }
        this.relationships.button_set.data.id = button_set
          ? parseInt(button_set)
          : null;
      }
    }
  },

  created() {
    this.pending.load = true;
    Promise.all([
      this.getButtonsSets(),
      this.getListing(),
      this.getCompany()
    ]).finally(() => (this.pending.load = false));
  },

  methods: {
    async copyToClipboard() {
      if (await this.$clipboard(this.listing.uuid)) {
        this.$toast.success(
          this.$t("publications.shared-form.copy-successfull")
        );
      } else {
        this.$toast.error(this.$t("publications.shared-form.copy-error"));
      }
    },

    async getListing() {
      return this.$http
        .get("listings/" + this.ID)
        .then(({ data }) => {
          payloadRewriter(this.initialListing, data.data.attributes);
          payloadRewriter(this.listing, data.data.attributes);
          payloadRewriter(this.options, data.data.meta);
          payloadRewriter(this.relationships, data.data.relationships);
          if (data.data.relationships.button_set.data) {
            this.initialListing.button_set =
              data.data.relationships.button_set.data.id;
            this.listing.button_set =
              data.data.relationships.button_set.data.id;
          }
          this.ownerName = data.data.attributes.owner_name;
          let initialButtonSet = data.included.find(
            ({ type }) => type === "button_set"
          );
          if (initialButtonSet) this.initialButtonSet = initialButtonSet;

          this.listingTitle = JSON.parse(JSON.stringify(this.listing.title));
        })
        .catch(() => {
          this.$router.push({ name: "CarsInventoryPublications" });
        });
    },

    async getButtonsSets() {
      const { data } = await this.$http.get("button_sets");
      this.sets = data.data;
    },

    async getCompany() {
      return this.$http.get(`company`).then(response => {
        const { id } = response.data.data;
        this.currentCompanyId = id;
      });
    },

    updateListing() {
      this.pending.update = true;
      const { listing, relationships } = this;
      const LISTING = {
        data: {
          attributes: {
            sort_attribute: listing.sort_attribute,
            sort_order: listing.sort_order,
            title: listing.title
          },

          relationships
        }
      };
      this.$http
        .patch("listings/" + this.ID, LISTING)
        .then(response => {
          const { title } = response.data.data.attributes;
          this.$toast.info(
            this.$t(`publications.shared-form.success-update-with-title`, {
              title
            })
          );
        })
        .finally(() => (this.pending.update = false));
    },

    openDeleteModal() {
      this.deleteModalOpened = true;
    },
    closeDeleteModal() {
      this.deleteModalOpened = false;
    },
    deleteList() {
      this.pending.delete = true;
      this.$http
        .delete(`listings/${this.ID}`)
        .then(() => {
          this.$store.dispatch("listings/fetchAll");
          this.$router.push({ name: "CarsInventoryPublications" });
          this.$toast.info(
            this.$t("publications.shared-form.successfully-deleted")
          );
        })
        .finally(() => (this.pending.delete = false));
    },

    openWarningModal() {
      this.warningModalOpened = true;
    },
    leavePage() {
      this.$router.push({
        name: "CarsInventoryPublications"
      });
    },

    openButtonSetModal() {
      this.buttonSetModalOpened = true;
    },
    closeButtonSetModal() {
      this.buttonSetModalOpened = false;
    },
    onButtonSetSave(payload) {
      this.closeButtonSetModal();
      this.pending.load = true;
      this.getButtonsSets().then(() => {
        this.listing.button_set = payload.id;
        this.pending.load = false;
      });
    }
  }
};
</script>

<style lang="scss">
.ci-action--back {
  color: $grey-10;
  &:active:not(:disabled),
  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    color: $blue-6;
    border-color: transparent;
    &:before {
      opacity: 0;
    }
  }
}
.ci-publications__info-box {
  padding: 14px;
  .rdx-icon {
    font-size: 20px;
    line-height: 20px;
    vertical-align: middle;
  }
  &--error {
    color: $red-7;
    background: $red-1;
  }
}

.edit-list-view {
  &__inner {
    display: block;
    width: 100%;
    height: calc(100vh - 166px);
    overflow: auto;
  }

  &__header {
    border-bottom: 1px solid $grey-5;
    padding: 17px 24px 21px;
  }

  &__grid {
    display: grid;
    grid-template-columns: 25% 75%;
    > div {
      padding: 0rem 2rem;
    }
  }

  &__actions {
    position: absolute;
    right: 1.5rem;
  }

  &__link {
    cursor: pointer;
    &:before {
      content: "|";
      color: $grey-7;
      margin: 0 5px;
    }
  }

  &__modal {
    &--fullscreen {
      > .rdx-modal-box {
        width: 100%;
        height: 100%;
        animation: none;
        border-radius: 0;
        .rdx-modal-box__content {
          max-height: unset;
        }
      }
    }
  }
}
</style>
